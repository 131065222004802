/**
 * Created by Asad on 9/7/2019.
 */
export default {
    data(){
        return {
            loading: false,
            isFullPage: false,
            errors: {},
            success: false,
            msg: '',
        }
    },
    methods: {
        showSuccessMessage(msg){
            this.success = true;
            this.msg = msg;
            let app = this;
            setTimeout(function () {
                app.success = false;
            }, 3000);
        },
        clearErrors(){
            this.errors = {};
        },

        startRequest(){
            this.loading = true;
        }, endRequest(){
            this.loading = false;
        },
        setErrors(response){

            if (response.status != 422) {
                this.general_error = 'Error occurred  on server, please try later';
                return;
            }
            this.errors = response.data.errors;
        },
        getError(key){
            if (this.errors[key] == undefined) {
                return;
            }
            return this.errors[key][0];
        },
        getErrors(){
            return this.errors;
        }
    }
}