<template>
    <div></div>
</template>
<script>
    export default{
        props: [
            'url', 'data', 'trigger', 'request'
        ],
        methods: {
            execute(){
                if (this.request == 'get') {
                    this.getRequest();
                    return;
                }
                this.postRequest();

            },
            getRequest(){
                let app = this;
                this.$emit('request');
                this.axios.get(app.url, app.data).then((response) => {
                    app.$emit('success', response.data);
                }).catch((error) => {
                    app.$emit('error', error);
                }).finally(() => {
                    app.$emit('request');
                });
            },
            postRequest(){
                let app = this;
                this.$emit('request');

                this.axios.post(app.url, app.data).then((response) => {
//                    console.log(response.data);
                    app.$emit('success', response);
                }).catch((error) => {
                    app.$emit('error', error);
                }).finally(() => {
                    app.$emit('request');
                });
            },
        },
        watch: {
            trigger(value){

                if (!value) {
                    return;
                }
                this.execute();
            }
        }

    }
</script>