<template>
    <div class="row">
        <div class="col-12 ">

            <router-link :to="{name:'items'}" v-if="type!='items'"
                         class="btn btn-sm btn-outline-info  mr-3 float-right mb-3 "
            >Manage Items
            </router-link>
            <router-link :to="{name:'inventory'}" v-if="type !='inventories'"
                         class="btn btn-sm btn-outline-info  mr-3 float-right mb-3 "
            >Manage Inventory
            </router-link>
            <router-link :to="{name:'category'}" v-if="type!='categories'"
                         class="btn btn-sm btn-outline-info  mr-3 float-right mb-3 "
            >Item Categories
            </router-link>
            <router-link :to="{name:'units'}" v-if="type!='units'"
                         class="btn btn-sm btn-outline-info  mr-3 float-right mb-3 "
            >Units of Measure
            </router-link>
            <router-link :to="{name:'brands'}" v-if="type!='brands'"
                         class="btn btn-sm btn-outline-info  mr-3 float-right mb-3 "
            >Item Brands
            </router-link>

        </div>

    </div>
</template>
<script>
    export default{
        props: ['type']
    }
</script>