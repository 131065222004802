<template>
    <div>
        <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleBrands"
               v-on:error="setErrors($event.response)"
               v-on:request="trigger=false;"></axios>

        <div class="container-fluid pl-5 pr-5 content-container">
            <div class="row">
                <div class="col-sm-12 bg-light shadow  p-3 text-left">
                    <p class="heading">Item Brands</p>
                    <hr/>
                    <im type="brands"></im>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <success-alert :show="success" :message="msg"></success-alert>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Active</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(brand,i) in brands" v-bind:key="i">
                                <td>{{i + 1}}</td>

                                <td>
                                    <input type="text" @change="update(i)" class="form-control" v-model="brand.name">
                                    <span class="text-danger">{{getError('name')}}</span>
                                </td>
                                <td>
                                    <input type="text" @change="update(i)" class="form-control" v-model="brand.code">
                                    <span class="text-danger">{{getError('code')}}</span>
                                </td>
                                <td><input type="checkbox" @change="update(i)" v-model="brand.is_active"></td>
                                <td>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>
<script>

    import form from '@/forms';
    import {Paths} from '@/paths';
    import axios from '@/views/actions/Axios';
    //    import add from '@/views/unit/Add';
    import im from '@/views/layouts/InventoryMenu';
    export default{
        extends: form,
        components: {axios, im},
        data(){
            return {
                url: '',
                add_url: '',
                update_url: '',
                brands: [],
                index: -1,
                trigger: false,
                request: 'get',
                data: {id: 0, name: '', code: '', is_active: true},

            }
        },
        watch: {
            brands(newBrands){
                this.$store.commit('setBrands', newBrands.filter(function (i) {
                    return i.id > 0;
                }));
            }
        },
        methods: {
            empty_model: function () {

                return {id: 0, name: '', code: '', is_active: true};
            },
            handleBrands(response){
                if (this.url == Paths.brand.index) {
                    this.setBrands(response);
                    this.brands.push(this.empty_model());
                    return;
                }
                this.brandUpdated(response.data);
            },
            update(i){
                this.request = 'post';
                this.url = Paths.brand.save;
                this.index = i;
                this.data = this.brands[i];
                if (this.data.name.length == 0) {
                    return;
                }
                this.trigger = true;
            },
            setBrands(data)
            {
                this.brands = data;
            },
            unitAdded(unit){
                this.units.push(unit);
                this.$store.commit('setUnits', this.units);
                this.showSuccessMessage('Unit added successfully');
            },
            brandUpdated(data){
                this.resetRequest();
                if (this.brands[this.index].id == 0) {
                    this.brands.push(this.empty_model());
                }

                this.brands[this.index] = data;
                this.index = -1;
            },
            resetRequest(){
                this.request = 'get';
                this.url = Paths.brand.index;
            }
        },
        mounted(){
            this.url = Paths.brand.index;

            this.trigger = true;

        }

    }
</script>